























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Input extends Vue {
  @Prop({ required: true })
  type!: string;

  @Prop({ required: false })
  label?: string;

  @Prop({ required: false })
  iconPrefix?: string;

  @Prop({ required: false })
  iconSuffix?: string;

  @Prop({ required: false })
  value?: string;

  @Prop({ required: false })
  placeholder!: string;

  @Prop({ required: false })
  centerPlaceholder?: boolean;
}
